<template>
  <div class="table">
    <div class="search-top">
      <!-- <div>
        <span>审核状态：</span>
        <el-select v-model="params.hum_state">
          <el-option label="1" value="1"></el-option>
        </el-select>
      </div> -->
      <div>
        <span>上报时间：</span>
        <el-date-picker
          v-model="dateTime"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          placeholder="请选择时间"
        ></el-date-picker>
      </div>
      <!-- <div>
        <span>平台：</span>
        <el-select v-model="params.app_name">
          <el-option label="萌购" :value="1"></el-option>
          <el-option label="任你购" :value="2"></el-option>
          <el-option label="西猫" :value="3"></el-option>
        </el-select>
      </div> -->
      <el-button type="primary" @click="getList">查询</el-button>
      <el-button @click="clear">重置</el-button>
    </div>
    <div>
      <!-- <el-button type="primary" @click="listCtl(1)">批量通过</el-button>
      <el-button type="primary" @click="listCtl(2)">批量拒绝</el-button> -->
      <!-- <el-button type="primary" @click="download">下载数据</el-button> -->
    </div>
    <DragTable :list-query="list" :header="header" :select="false" @select-change="selChange">
      <template #sysstate="{row}">
        <span v-if="row.hum_state === 1" class="word-color" :class="{'red': row.sys_state === 3}">{{sysstate[row.sys_state]}}</span>
        <span v-else class="word-color" :class="{'red': row.hum_state === 3}">{{row.hum_state === 2 ? '人审通过' : '人审拒绝'}}</span>
      </template>
      <template #control="{ row }">
        <div v-if="row.hum_state === 1" class="control">
          <span class="green" @click="ctl(row,2)">通过</span>
          <span class="red" @click="ctl(row,3)">拒绝</span>
        </div>
        <span v-else>-</span>
      </template>
    </DragTable>
    <footer class="table-footer">
      <p></p>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </footer>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";

export default defineComponent({
  setup(prop, { root }) {
    const sysstate = {
      1: '待处理',
      2: '机审通过',
      3: '机审拒绝'
    }
    const total = ref(10);
    const dateTime = ref('')
    const params = reactive({
      page: 1,
      limit: 10,
      search_start: '',
      search_end: '',
      tab: 1,
      app_name: 2
    });
    watch(dateTime,val=>{
      if(val) {
        params.search_start = root.$dayjs(val[0]).unix()
        params.search_end = root.$dayjs(val[1]).unix()
      } else {
        params.search_start = undefined
        params.search_end = undefined
      }
      getList()
    })
    const header = [
      { name: "用户ID", value: "user_id", width: 100 },
      { name: "昵称申请", value: "nickname" },
      { name: "触发词汇", value: "trigger_word" },
      { name: "触发词库", value: "trigger_desc" },
      { name: "上报时间", value: "create_at" },
      { name: "用户IP", value: "ip" },
      { name: "状态", value: "sys_state", slot: 'sysstate' },
      // { name: "编辑", value: "", slot: "control", width: 130 },
      // { name: "操作人", value: "operation_user" },
      // { name: "更新时间", value: "operation_at" },
    ];
    const list = reactive({ data: [] });
    const getList = () => {
      root.$axios
        .get("/rcp/userManage/nickname/list", {
          params,
        })
        .then((res) => {
          list.data = res.data.list;
          total.value = res.data.total;
        });
    };
    const ctl = async (item, num)=>{
      const confirm = await root.$goDialog(`确定要${num ===2 ? '通过':'拒绝'}吗？`)
      if(!confirm) return
      root.$axios
        .post("/rcp/userManage/nickname/updateState", {
          id: item.id,
          state: num,
        })
        .then((res) => {
          if(res.code === 10000) {
            root.$message.success(res.msg)
            getList()
          } else {
            root.$message.error(res.msg)
          }
        })
        
    }
    const listCtl = async (num) => {
      const confirm = await root.$goDialog(`确定要${num ===2 ? '通过':'拒绝'}吗？`)
      if(!confirm) return
      root.$axios
        .post("/rcp/userManage/nickname/updateState", )
        .then((res) => {
            root.$message.success(res.msg)
            getList()
        })
        
    }
    const clear = () => {
      for (let key in params) {
        params[key] = undefined;
      }
      params.page = 1;
      params.limit = 10;
      params.app_name = 2
      params.tab = 1
      dateTime.value = undefined
      getList();
    };
    const download = () => {
      if(!dateTime.value) {
        root.$message.warning('请选择日期时间')
        return
      }
      root.$axios
        .get("/rcp/userManage/nickname/download", {
          params: {
            search_start_time: root.$dayjs(dateTime.value[0]).unix(),
            search_end_time: root.$dayjs(dateTime.value[1]).unix()
          }
        })
        .then((res) => {
          if(res.code === 10000) {
            const a = window.document.createElement('a')
            a.href= root.$store.state.CMS.url+res.data.file_path
            a.click()
          } else {
            root.$message.error(res.msg)
          }
        })
        
    };
    const handleSizeChange = (size) => {
      params.limit = size;
      params.page = 1
      getList();
    };
    const handleCurrentChange = (index) => {
      params.page = index;
      getList();
    };
    getList();
    const selChange = (arr) => {
      console.log(arr)
    }
    return {
      dateTime,
      sysstate,
      total,
      params,
      list,
      header,
      selChange,
      download,
      clear,
      getList,
      handleSizeChange,
      handleCurrentChange,
      ctl,
      listCtl
    };
  },
})
</script>
<style lang="scss" scoped>
.table {
  width: 100%;
  // display: flex;
  // flex-direction: column;
}
.search-top {
  display: flex;
  margin-top: 15px;
  align-items: center;
  margin-bottom: 15px;
  & > div {
    display: flex;
    align-items: center;
    margin-right: 15px;
    span {
      font-size: 14px;
      white-space: nowrap;
      text-align: right;
      flex-shrink: 0;
    }
    &:nth-of-type(2) {
      span {
        width: 70px;
      }
    }
    &:nth-of-type(3) {
      .input {
        width: 350px;
      }
    }
  }
}
.status {
  color: $safeColor;
  position: relative;
  padding-left: 13px;
  &.red {
    color: $dangerColor;
  }
}
.control {
  display: flex;
  justify-content: center;
  span {
    margin-right: 14px;
    // cursor: pointer;
    font-size: 14px;
    color: $mainColor;
    &:last-of-type(1) {
      margin-right: 0;
    }
    &.red {
      color: $dangerColor;
    }
    &.green {
      color: $safeColor;
    }
  }
}
.table-img {
  width: 100%;
  object-fit: contain;
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
<template>
  <div>
    <div style="width:300px;">
      <switch-text v-model="sel" left="普通&高危昵称" right="屏蔽词昵称"></switch-text>
    </div>
    <common v-if="sel"></common>
    <sheild v-else></sheild>
  </div>
</template>
<script>
import { defineComponent, ref } from "@vue/composition-api";
import SwitchText from "../../../components/common/SwitchText.vue";
import common from './userName/common.vue'
import Sheild from "./userName/sheild.vue";

export default defineComponent({
  components: { SwitchText, common, Sheild },
  setup(props, { root }) {
    const sel = ref(true)
    return {
      sel
    }
  },
});
</script>
<style lang="scss" scoped>
</style>
